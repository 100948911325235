@font-face {
  font-family: 'AquawaxPro';
  src:
    url("../fonts/AquawaxPro-Regular/AquawaxPro-Regular.woff2") format("woff2"),
    url("../fonts/AquawaxPro-Regular/AquawaxPro-Regular.woff") format("woff");
  style: normal;
  weight: normal;
}

@font-face {
  font-family: 'AquawaxPro';
  src:
    url("../fonts/AquawaxPro-Medium/AquawaxPro-Medium.woff2") format("woff2"),
    url("../fonts/AquawaxPro-Medium/AquawaxPro-Medium.woff") format("woff");
  style: normal;
  weight: 600;
}
