#portraitNotice {
  align-items: center;
  background: $primary-color;
  color: white;
  display: none;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  padding: 0 20px;
  position: fixed;
  text-align: center;
  width: 100vw;
  z-index: 999;
}

#portraitNotice img {
  max-width: 200px;
  margin-bottom: 20px;
}

/* only when orientation is in portrait mode */
@media all and (orientation: portrait) {
  #portraitNotice {
    display: flex;
  }

  body {
    overflow: hidden;
  }
}
