* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'AquawaxPro', sans-serif;
  font-size: 16px;
  font-weight: 600;
  background: $primary-color;
  height: 500vh;
}

header {
  width: 100%;
  position: fixed;
  z-index: 1;
  background: $primary-color;
  padding: 5px 0;
  margin: 0;
  border: none;
  display: none;

  @media (min-width: 992px) {
    padding: 15px 0;
    display: block;
  }
}

.container {
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
}

#content-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  overflow-x: hidden;
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  #content-wrapper,
  #example-wrapper {
    height: calc(100% - 74px);
  }
}

.scrollContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
}

#example-wrapper .scrollContent {
  height: 100%;
}
