section#titlechart {
  text-align: center;
  position: relative;
  min-height: 500px;
  min-width: 100%;
  height: 100%;

  #description {
    left: 50%;
    top: 50%;
    position: absolute;
    align-items: center;
    animation-duration: 4.5s;
    animation-name: titleappear;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    transform: translate(-50%, -50%);

    img {
      width: 650px;
      margin-top: 100px;

      @media (max-width: 576px) {
        width: 250px;
      }

      @media (max-width: 991px) {
        width: 300px;
      }
    }

    @media (min-width: 991px) {
      top: 50%;
    }

    .instructions-wrapper {
      position: relative;
      transform: none;
      left: 0;
      display: none;
      margin-top: 40px;

      img.drag-icon {
        margin: 0 auto 10px;
        width: 20px;
      }

      @media (max-width:  990px) {
        display: block;
      }
    }
  }

  .instructions-wrapper {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100%;
    animation: 8s ease 0s normal forwards 1 fadeIn;
    transform: translateX(-50%);
    bottom: 0;
    display: none;

    p small {
      color: white;
      font-weight: lighter;
    }

    img.scroll-icon {
      margin: 0 auto 20px;
      width: 30px;
    }

    @media (min-width:  991px) {
      display: block;
    }
  }
}

.frame-scene {
  position: relative;
  text-align: center;
  height: 100vh;

  .spacer {
    min-height: 200px;

    @media (max-width: 992px) {
      height: 100px;
    }
  }

  .box {
    min-width: 50px;
    font-weight: lighter;
    color: white;
    position: absolute;
    font-size: 2em;
    width: 100%;
    margin: 0;

    &.-title {
      text-align: center;
      font-size: 4.5em;
    }

    &.-center {
      text-align: center;
    }

    &.-left {
      text-align: left;
      left: 4%;
    }

    &.-right {
      text-align: right;
      right: 5%;
    }

    @media (max-width: 992px) {
      font-size: 100%;
    }
  }

  .reveal {
    opacity: 0;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;

    &.visible {
      opacity: 1;
      transform: none;
    }
  }

  .scene-text {
    text-transform: uppercase;
    img {
      width: 800px;

      @media (max-width: 576px) {
        width: 250px;
      }

      @media (max-width: 991px) {
        width: 300px;
      }
    }

    small.copy {
      font-size: 40%;
      position: relative;
      bottom: 0;
      color: white;
    }
  }

}
