@keyframes titleappear {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }

  33% {
    opacity: 0.2;
  }

  75% {
    transform: translate(-50%, -50%);
    opacity: 0.6;
  }

  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
