$body-color: slateblue;
$primary-color: #008aae;

@import 'fonts';
@import 'animations';
@import 'layout';
@import 'portrait-notice';
@import 'frames';

canvas {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1920px;
  width: 100%;
  max-height: 100vh;
}
